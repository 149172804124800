import {postRequest} from './base';
import {whatWentWrongNeedsInformation} from '../utils/questions';

const postTooManyPartsContact = (contact) => postRequest(`/customer/`, contact);

const computeQuestions = (orderData) => {
  const {questions, contact} = orderData;
  const computedQuestions = [
    {field: 'purchased_at', value: questions.productPurchasedAt},
    {field: 'what_went_wrong', value: questions.whatWentWrong},
    {field: 'comments', value: contact.comments},
  ];
  if (questions.hasProductBox) {
    computedQuestions.push({
      field: 'batch_number',
      value: questions.batchNumber,
    });
  }
  if (whatWentWrongNeedsInformation(questions.whatWentWrong)) {
    computedQuestions.push({
      field: 'what_went_wrong_information',
      value: questions.whatWentWrongInformation,
    });
  }
  return computedQuestions;
};

const postOrder = (orderData) => {
  const {parts, contact, shipping} = orderData;

  const requestOrder = {
    shipToCountry: shipping.shipToCountry,
    shipToState: shipping.shipToState,
    shipToFirstName: shipping.shipToFirstName,
    shipToLastName: shipping.shipToLastName,
    shipToEmail: contact.shipToEmail,
    shipToZipCode: shipping.shipToZipCode,
    shipToPhone: contact.shipToPhone,
    shipToAddress1: shipping.shipToAddress1,
    shipToAddress2: shipping.shipToAddress2,
    shipToCity: shipping.shipToCity,
    formValues: computeQuestions(orderData),
    items: parts
      .filter((part) => part.quantity)
      .map((part) => ({
        quantity: part.quantity,
        partOdooId: part.part_odoo_id || part.partOdooId,
        partBackofficeId: part.id,
        sku: part.sku,
        name: part.name,
      })),
  };

  return postRequest(`/order/`, requestOrder);
};

export {postTooManyPartsContact, postOrder};
