import * as yup from 'yup';

import {validatePhone, validateEmail} from '../../utils/validations';

const schema = yup.object().shape({
  shipToPhone: yup
    .string()
    .required()
    .test('valid-phone', 'Please enter a valid phone.', validatePhone),
  shipToEmail: yup
    .string()
    .required()
    .test('valid-email', 'Please enter a valid email.', validateEmail),
  comments: yup.string(),
});

export default schema;
