import React from 'react';
import styled from 'styled-components';

import Title from '../Title';
import Button from '../Button';
import Subtitle from '../Subtitle';

const Container = styled.div`
  ${(props) => props.theme.containers.sm}
  flex: 1;
  margin-bottom: 40px;
  ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 60px;
  }
`;

const Content = styled.div`
  color: ${(props) => props.theme.colors.bondiBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

const PageHeader = styled.div`
  margin-top: 0;
  margin-bottom: 12px;
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const ButtonsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  max-width: ${(props) => props.theme.constants.actionButtonsContainer};
  > button {
    flex: 1;
  }
  > button:not(:last-child) {
    margin-right: 10px;
  }
`;

const Page = ({
  children,
  title,
  titleHighlight,
  onBackClick,
  onNextClick,
  buttons,
  className,
  subtitle,
  disabledNextButton,
}) => {
  const showButtons = onBackClick || onNextClick || buttons;

  return (
    <Container className={className}>
      <Content>
        {(title || titleHighlight || subtitle) && (
          <PageHeader>
            {(title || titleHighlight) && (
              <Title>
                {title} <Highlight>{titleHighlight}</Highlight>
              </Title>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </PageHeader>
        )}
        {children}
        {showButtons && (
          <ButtonsWrapper>
            <ButtonsContainer>
              {onBackClick && (
                <Button
                  onClick={onBackClick}
                  iconBefore="angle-left"
                  label="Back"
                />
              )}
              {buttons}
              {onNextClick && (
                <Button
                  onClick={onNextClick}
                  iconAfter="angle-right"
                  label="Next"
                  disabled={disabledNextButton}
                />
              )}
            </ButtonsContainer>
          </ButtonsWrapper>
        )}
      </Content>
    </Container>
  );
};

export default Page;
