import React from 'react';
import styled from 'styled-components';

import Label from '../Label';

const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.nobel};
`;

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin: 0 7px 0 0;
  }
  > label:not(:last-child) {
    margin-right: 12px;
  }
`;

const RadioButton = styled.label`
  display: flex;
  align-items: center;
`;

const RadioButtons = ({options, name, value, onChange, label, required}) => (
  <Container>
    <Label required={required}>{label}</Label>
    <InputsContainer>
      {options.map((option) => (
        <RadioButton key={option.value}>
          <input
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
          />
          {option.label}
        </RadioButton>
      ))}
    </InputsContainer>
  </Container>
);

export default RadioButtons;
