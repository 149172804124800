import {
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_ERROR,
  PART_SELECT,
  PART_CHANGE_QUANTITY,
} from './product.actions';

const initialState = {
  product: {},
  parts: [],
  isFetching: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT: {
      return {
        isFetching: true,
      };
    }
    case FETCH_PRODUCT_SUCCESS: {
      return {
        isFetching: false,
        product: action.payload.product,
        parts: action.payload.parts,
      };
    }
    case FETCH_PRODUCT_ERROR: {
      return {
        isFetching: false,
        error: action.payload.error,
      };
    }
    case PART_SELECT: {
      const copiedParts = [...state.parts];
      const selectedPartIndex = state.parts.findIndex(
        (part) => part.sku === action.payload.sku,
      );
      const newPartSelected = !copiedParts[selectedPartIndex].selected;
      copiedParts[selectedPartIndex] = {
        ...copiedParts[selectedPartIndex],
        selected: newPartSelected,
        quantity: newPartSelected ? 1 : 0,
      };
      return {
        ...state,
        parts: copiedParts,
      };
    }
    case PART_CHANGE_QUANTITY: {
      const copiedParts = [...state.parts];
      const changedPartIndex = state.parts.findIndex(
        (part) => part.sku === action.payload.sku,
      );
      copiedParts[changedPartIndex] = {
        ...copiedParts[changedPartIndex],
        quantity: action.payload.quantity,
      };
      return {
        ...state,
        parts: copiedParts,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
