import * as yup from 'yup';

import {validatePhone, validateEmail} from '../../../utils/validations';

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  preferredContactMethod: yup.string().required(),
  phone: yup
    .string()
    .when('preferredContactMethod', {
      is: 'phone',
      then: yup.string().required(),
    })
    .test('valid-phone', 'Please enter a valid phone.', (value) =>
      value.length ? validatePhone(value) : true,
    ),
  email: yup
    .string()
    .when('preferredContactMethod', {
      is: 'email',
      then: yup.string().required(),
    })
    .test('valid-email', 'Please enter a valid email.', (value) =>
      value.length ? validateEmail(value) : true,
    ),
});

export default schema;
