const SET_QUESTIONS = 'orderDetails/SET_QUESTIONS';
const SET_SHIPPING = 'orderDetails/SET_SHIPPING';
const SET_CONTACT = 'orderDetails/SET_CONTACT';

const setQuestions = (questions) => ({
  type: SET_QUESTIONS,
  payload: {
    questions,
  },
});

const setShipping = (shipping) => ({
  type: SET_SHIPPING,
  payload: {
    shipping,
  },
});

const setContact = (contact) => ({
  type: SET_CONTACT,
  payload: {
    contact,
  },
});

export {
  SET_QUESTIONS,
  SET_SHIPPING,
  SET_CONTACT,
  setQuestions,
  setShipping,
  setContact,
};
