import React from 'react';
import {ThemeProvider} from 'styled-components';
import {BrowserRouter as Router} from 'react-router-dom';
import styled from 'styled-components';
import {Provider} from 'react-redux';

import './utils/yupLocale';
import store from './store';
import theme from './theme';
import Header from './components/Layout/Header';
import Nav from './components/Layout/Nav';
import Routes from './pages/Routes';
import WhoWeAre from './components/Layout/WhoWeAre';
import Footer from './components/Layout/Footer';
import ScrollToTop from './components/Layout/ScrollToTop';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.constants.headerHeight};
`;

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router basename={process.env.REACT_APP_BASENAME || '/'}>
        <ScrollToTop />
        <Header />
        <Container>
          <Nav />
          <Routes />
          <WhoWeAre />
        </Container>
        <Footer />
      </Router>
    </ThemeProvider>
  </Provider>
);

export default App;
