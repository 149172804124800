import {call, put, takeLatest} from 'redux-saga/effects';

import {
  SEARCH_PRODUCT,
  fetchProduct,
  fetchProductSuccess,
  fetchProductError,
} from './product.actions';
import {changeStep, saveSession} from '../application/application.actions';
import {searchProduct as apiSearchProduct} from '../../api/catalog';

import ROUTES from '../../constants/routes';

function* handler() {
  yield takeLatest(SEARCH_PRODUCT, searchProduct);
}

function* searchProduct(action) {
  const {sku, history} = action.payload;
  yield put({type: 'RESET_ORDER'});
  try {
    yield put(fetchProduct());
    const {parts, ...product} = yield call(apiSearchProduct, sku);
    yield put(fetchProductSuccess(product, parts));
    yield put(saveSession(ROUTES.CONFIRM_PRODUCT, product, parts));
    yield put(changeStep(history, ROUTES.CONFIRM_PRODUCT));
  } catch (error) {
    yield put(fetchProductError(error));
  }
}

export default handler;
