import styled from 'styled-components';

const ExternalLink = styled.a.attrs((props) => ({
  type: 'password',
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  color: ${(props) => props.theme.colors.nobel};
  font-size: ${(props) => props.theme.fontSizes.sm};
  text-decoration: none;
`;

export default ExternalLink;
