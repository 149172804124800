import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import {CONTACT_EMAIL, CONTACT_PHONE} from '../../../constants/contact';

const Instructions = styled.p`
  margin: 0;
`;

const NotFoundModal = ({onOk, productCode}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
    title="Product not found:"
  >
    <Instructions>
      {productCode} not found. Please check your box label to ensure you have
      the right product code. If you are certain you have the correct product
      code, please call {CONTACT_PHONE} or email {CONTACT_EMAIL} to speak with
      customer care about your replacement part needs. There are some products
      that have been prone to abuse in the past, so we request calls or emails
      to keep pricing on our products as competitive as possible.
    </Instructions>
  </Modal>
);

export default NotFoundModal;
