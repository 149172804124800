import React from 'react';
import styled from 'styled-components';

import Modal from '../Modal';
import Button from '../Button';
import noImage from "../../assets/images/no-image.png";


const PartCode = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  position: absolute;
  left: 30px;
`;

const Image = styled.img`
  align-self: center;
  max-width: 80%;
  height: auto;
  max-height: 80%;
  width: 80%;
`;

const PartName = styled.p`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.lg};
`;

const PartImageModal = ({part, onOk}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
  >
    <PartCode>{part.letter}</PartCode>
    <Image alt={part.name} src={part.image?.url_small || part.image?.urlSmall || noImage} />
    <PartName>{part.name}</PartName>
  </Modal>
);

export default PartImageModal;
