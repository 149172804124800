import React, {useEffect, useRef} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import Title from '../../components/Title';
import ExternalLink from '../../components/ExternalLink';
import {ReactComponent as LogoNych} from '../../assets/icons/footer-logo.svg';
import ROUTES, {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  NYCH_GROUP_URL,
} from '../../constants/routes';
import {CONTACT_EMAIL, CONTACT_PHONE} from '../../constants/contact';
import {toggleShowFooter} from '../../store/application/application.actions';
import {selectShowFooter} from '../../store/application/application.selectors';

const ToggleContainer = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.primary};
  width: 90px;
  height: 25px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -25px;
  position: absolute;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.secondary};
`;

const Container = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};
`;

const ContentContainer = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
  ${(props) =>
    props.showContent &&
    css`
      max-height: 600px;
      border-bottom: 1px solid ${(props) => props.theme.colors.denim};
    `}
`;

const Content = styled.div`
  ${(props) => props.theme.containers.sm}
  display: flex;
  justify-content: center;
  flex-direction: column;
  & {
    padding-top: 60px;
    padding-bottom: 25px;
  }
  ${(props) => props.theme.breakpoints.lg} {
    padding-bottom: 56px;
    flex-direction: row;
    justify-content: center;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  padding-bottom: 30px;
  ${(props) => props.theme.breakpoints.lg} {
    padding-right: 40px;
    padding-bottom: 0;
    border-bottom: 0;
    border-right: 1px solid ${(props) => props.theme.colors.secondary};
  }
`;

const StyledLogoNych = styled(LogoNych)`
  width: 170px;
`;

const SocialNetworks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 14px;
`;

const SocialNetworkIcon = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 50%;
  width: 26px;
  height: 26px;
  margin: 0 8px;
  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
  }
`;

const NychLinkHighlight = styled.span`
  color: ${(props) => props.theme.colors.secondary};
`;

const NychLink = styled(ExternalLink)`
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 25px;
  padding: 12px 50px;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  :hover {
    background-color: ${(props) => props.theme.colors.secondary};
    ${NychLinkHighlight} {
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

const ContactInfo = styled.div`
  padding-top: 30px;
  ${(props) => props.theme.breakpoints.lg} {
    padding-left: 40px;
    padding-top: 10px;
  }
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.colors.white};
`;

const ContactInfoRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 12px 0;
  color: ${(props) => props.theme.colors.white};

  ${(props) => props.theme.breakpoints.lg} {
    justify-content: flex-start;
  }

  :hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const ConcactIconContainer = styled.div`
  width: 16px;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.secondary};
`;

const scrollToBottom = () => window.scrollTo(0, document.body.scrollHeight);

const KeepInTouch = () => {
  const dispatch = useDispatch();
  const showFooter = useSelector(selectShowFooter);
  const contentContainerRef = useRef();

  useEffect(() => {
    const contentContainerEl = contentContainerRef?.current;
    if (contentContainerEl && showFooter) {
      contentContainerEl.addEventListener('transitionend', scrollToBottom);
    }
    return () => {
      contentContainerEl?.removeEventListener('transitionend', scrollToBottom);
    };
  }, [showFooter]);

  return (
    <Container id={ROUTES.CONTACT.replace('#', '')}>
      <ToggleContainer
        onClick={() => dispatch(toggleShowFooter())}
        role="button"
      >
        <ToggleIcon icon={showFooter ? 'angle-down' : 'angle-up'} size="2x" />
      </ToggleContainer>
      <ContentContainer showContent={showFooter} ref={contentContainerRef}>
        <Content>
          <Links>
            <StyledLogoNych />
            <SocialNetworks>
              <SocialNetworkIcon href={FACEBOOK_URL}>
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </SocialNetworkIcon>
              <SocialNetworkIcon href={INSTAGRAM_URL}>
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </SocialNetworkIcon>
            </SocialNetworks>
            <NychLink href={NYCH_GROUP_URL}>
              www.<NychLinkHighlight>nych</NychLinkHighlight>brands.com
            </NychLink>
          </Links>
          <ContactInfo>
            <Title>
              <Highlight>Keep</Highlight> In Touch
            </Title>
            <ContactInfoRow>
              <ConcactIconContainer>
                <FontAwesomeIcon icon="phone" />
              </ConcactIconContainer>
              {CONTACT_PHONE}
            </ContactInfoRow>
            <ContactInfoRow as="a" href={`mailto:${CONTACT_EMAIL}`}>
              <ConcactIconContainer>
                <FontAwesomeIcon icon={['far', 'envelope']} />
              </ConcactIconContainer>
              Email Us
            </ContactInfoRow>
            <ContactInfoRow>
              <ConcactIconContainer>
                <FontAwesomeIcon icon="map-marker-alt" />
              </ConcactIconContainer>
              6935-K Reames Rd
            </ContactInfoRow>
            <ContactInfoRow>
              <ConcactIconContainer />
              Charlotte NC 28216
            </ContactInfoRow>
          </ContactInfo>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default KeepInTouch;
