export default {
  CHECKOUT_CONFIRM: '/checkout-confirm',
  CHECKOUT_CONTACT: '/checkout-contact',
  CHECKOUT_QUESTIONS: '/checkout-questions',
  CHECKOUT_SHIPPING: '/checkout-shipping',
  CONFIRM_PRODUCT: '/confirm-product',
  CONFIRM_ORDER: '/confirm-order',
  CONTACT: '#contact',
  HOME: '/',
  PARTS_QUANTITY: '/parts-quantity',
  SELECT_PARTS: '/select-parts',
  WHO_WE_ARE: '#who-we-are',
};

export const FACEBOOK_URL = 'https://facebook.com/ZippityOutdoorProducts/';
export const INSTAGRAM_URL = 'https://instagram.com/zippity_outdoor/?hl=en';
export const NYCH_GROUP_URL = 'https://nychbrands.com';
export const ENCLO_URL = 'https://wambamfence.com/blog/2021/02/coming-soon/';
export const WAMBAM_URL = 'https://wambamfence.com';
export const ZIPPITY_URL = 'https://zippity-outdoor.com';
