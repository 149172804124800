import {SET_QUESTIONS, SET_SHIPPING, SET_CONTACT} from './orderDetails.actions';

const initialState = {
  questions: {
    productPurchasedAt: '',
    whatWentWrong: '',
    whatWentWrongInformation: '',
    hasProductBox: '',
    batchNumber: '',
  },
  shipping: {
    shipToFirstName: '',
    shipToLastName: '',
    shipToAddress1: '',
    shipToAddress2: '',
    shipToCountry: '',
    shipToCity: '',
    shipToState: '',
    shipToZipCode: '',
  },
  contact: {
    shipToPhone: '',
    shipToEmail: '',
    comments: '',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.payload.questions,
      };
    }
    case SET_SHIPPING: {
      return {
        ...state,
        shipping: action.payload.shipping,
      };
    }
    case SET_CONTACT: {
      return {
        ...state,
        contact: action.payload.contact,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
