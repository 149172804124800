import React from 'react';
import styled from 'styled-components';

import KeepInTouch from './KeepInTouch';

const Container = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
`;

const Content = styled.div`
  ${(props) => props.theme.containers.lg}
  height: 40px;
  color: ${(props) => props.theme.colors.bondiBlue};
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

const Footer = () => (
  <Container>
    <KeepInTouch />
    <Content>Copyright © 2020 Nych Group. All Rights Reserved.</Content>
  </Container>
);

export default Footer;
