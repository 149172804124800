import {call, takeLatest, all, put} from 'redux-saga/effects';

import {
  APPLICATION_BOOTSTRAP_INIT,
  APPLICATION_CHANGE_STEP,
  APPLICATION_SAVE_SESSION,
  appliationBootstrapSuccess,
} from './application.actions';
import {fetchProductSuccess} from '../product/product.actions';
import ROUTES from '../../constants/routes';
import {getSession, postSession} from '../../api/session';
import {
  getShippingCountriesStates,
  getFormChoices,
  getFormLabels,
} from '../../api/shared';

function* handler() {
  yield takeLatest(APPLICATION_BOOTSTRAP_INIT, initApplicationBootstrap);
  yield takeLatest(APPLICATION_CHANGE_STEP, changeStep);
  yield takeLatest(APPLICATION_SAVE_SESSION, saveSession);
}

function* initApplicationBootstrap(action) {
  const {history} = action.payload;
  const [
    session,
    shippingCountriesStates,
    formChoicesPromise,
    formLabelsPromise,
  ] = yield all([
    call(getSession),
    call(getShippingCountriesStates),
    call(getFormChoices),
    call(getFormLabels),
  ]);
  window.WAMBAM = {
    SHIPPING_COUNTRIES_STATES: shippingCountriesStates,
    FORM_CHOICES: formChoicesPromise,
    FORM_LABELS: formLabelsPromise,
  };
  if (session) {
    yield put(fetchProductSuccess(session.product, session.parts));
    yield call(history.replace, session.step);
  } else {
    yield call(history.replace, ROUTES.HOME);
  }
  yield put(appliationBootstrapSuccess());
}

function* changeStep(action) {
  const {history, step} = action.payload;
  yield call(history.push, step);
}

function* saveSession(action) {
  const {step, product, parts} = action.payload;
  yield call(postSession, {product, parts, step});
}

export default handler;
