import styled, {css} from 'styled-components';

const Label = styled.label`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.sm};
  margin-bottom: 6px;
  ${(props) =>
    props.required &&
    css`
      &::after {
        margin-left: 5px;
        content: '*';
        color: ${(props) => props.theme.colors.punch};
      }
    `}
`;

export default Label;
