import {getRequest} from './base';

const getShippingCountriesStates = () =>
  getRequest('/shared/shipping-countries-states/');

const getFormChoices = () => getRequest('/shared/form-choices/');

const getFormLabels = () => getRequest('/shared/form-labels/');

export {getShippingCountriesStates, getFormChoices, getFormLabels};
