import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import productCodeImage from '../../../assets/images/product-code.png';

const Instructions = styled.p`
  margin: 0;
`;

const ProductCodeImage = styled.img`
  display: block;
  margin: 10px auto 0;
  max-width: 100%;
`;

const ProductCodeModal = ({onOk}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
    title="Where to find the product code:"
  >
    <Instructions>
      Your product code can be found on your box label or on page 2 of your
      installation guide under “Missing or Damaged Parts?”
    </Instructions>
    <ProductCodeImage src={productCodeImage} alt="Product code" />
  </Modal>
);

export default ProductCodeModal;
