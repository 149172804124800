import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import batchNumberImage from '../../../assets/images/batch-number.png';

const Instructions = styled.p`
  margin: 0;
`;

const BatchNumberImage = styled.img`
  display: block;
  margin: 10px auto 0;
  height: 45vh;
`;

const BatchNumberModal = ({onOk}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
    title="Where to find the batch number:"
  >
    <Instructions>
      Your batch number is a stamped series of 9 numbers that can be found on the
      end of the inner box.
      <BatchNumberImage src={batchNumberImage} alt="Batch number" />
    </Instructions>
  </Modal>
);

export default BatchNumberModal;
