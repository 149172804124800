const APPLICATION_BOOTSTRAP_INIT = 'application/APPLICATION_BOOTSTRAP_INIT';
const APPLICATION_BOOTSTRAP_SUCCESS =
  'application/APPLICATION_BOOTSTRAP_SUCCESS';
const APPLICATION_TOGGLE_SHOW_FOOTER = 'application/TOGGLE_SHOW_FOOTER';
const APPLICATION_CHANGE_STEP = 'application/CHANGE_STEP';
const APPLICATION_SAVE_SESSION = 'application/SAVE_SESSION';

const initApplicationBootstrap = (history) => ({
  type: APPLICATION_BOOTSTRAP_INIT,
  payload: {history},
});

const appliationBootstrapSuccess = () => ({
  type: APPLICATION_BOOTSTRAP_SUCCESS,
});

const toggleShowFooter = () => ({
  type: APPLICATION_TOGGLE_SHOW_FOOTER,
});

const changeStep = (history, step) => ({
  type: APPLICATION_CHANGE_STEP,
  payload: {
    history,
    step,
  },
});

const saveSession = (step, product, parts) => ({
  type: APPLICATION_SAVE_SESSION,
  payload: {
    step,
    product,
    parts,
  },
});

export {
  APPLICATION_BOOTSTRAP_INIT,
  APPLICATION_BOOTSTRAP_SUCCESS,
  APPLICATION_TOGGLE_SHOW_FOOTER,
  APPLICATION_CHANGE_STEP,
  APPLICATION_SAVE_SESSION,
  initApplicationBootstrap,
  appliationBootstrapSuccess,
  toggleShowFooter,
  changeStep,
  saveSession,
};
