import React from 'react';
import styled from 'styled-components';

import Label from '../Label';
import Input from '../Input';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Field = ({
  inputAs,
  children,
  className,
  label,
  name,
  required,
  ...restProps
}) => (
  <Container className={className}>
    {label && (
      <Label name={name} required={required}>
        {label}
      </Label>
    )}
    {children ? (
      React.cloneElement(children, restProps)
    ) : (
      <Input as={inputAs} name={name} id={name} {...restProps} />
    )}
  </Container>
);

export default Field;
