import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {searchProduct} from '../../store/product/product.actions';
import {
  selectIsFetchingProduct,
  selectError,
} from '../../store/product/product.selectors';
import ProductCodeModal from './components/ProductCodeModal';
import NotFoundModal from './components/NotFoundModal';
import BadConnectionModal from './components/BadConnectionModal';
import Page from '../../components/Layout/Page';
import Button from '../../components/Button';
import Field from '../../components/Field';

const SearchContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 12px;
  ${(props) => props.theme.breakpoints.lg} {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 6px;
  }
`;

const StyledField = styled(Field)`
  margin-bottom: 18px;
  ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 0;
    margin-right: 16px;
    flex: 1;
  }
`;

const SearchButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSizes.md};
  min-width: 150px;
`;

const QuestionButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSizes.sm};
  ${(props) => props.theme.breakpoints.lg} {
    margin-left: 15px;
    align-self: flex-start;
  }
`;

const HelpText = styled.span`
  color: ${(props) => props.theme.colors.nobel};
`;

const Home = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetchingProduct);
  const error = useSelector(selectError);
  const [sku, setSku] = useState('');
  const [showProductCodeModal, setShowProductCodeModal] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);
  const [showBadConnectionModal, setShowBadConnectionModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (error?.code === 'not_found') {
      setShowNotFoundModal(true);
    } else if (error) {
      setShowBadConnectionModal(true);
    }
  }, [error]);

  const handleSearchClick = useCallback(() => {
    dispatch(searchProduct(history, sku));
  }, [dispatch, history, sku]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
    }
  };

  return (
    <Page title="Enter your" titleHighlight="product code">
      {showProductCodeModal && (
        <ProductCodeModal onOk={() => setShowProductCodeModal(false)} />
      )}
      {showNotFoundModal && (
        <NotFoundModal
          onOk={() => setShowNotFoundModal(false)}
          productCode={sku}
        />
      )}
      {showBadConnectionModal && (
        <BadConnectionModal
          onOk={() => setShowBadConnectionModal(false)}
          productCode={sku}
        />
      )}
      <SearchContainer>
        <StyledField
          placeholder="Product code"
          value={sku}
          onKeyDown={handleKeyDown}
          onChange={(event) => setSku(event.target.value)}
        />
        <SearchButton
          onClick={handleSearchClick}
          disabled={!sku}
          iconBefore="search"
          label="Search"
          isLoading={isFetching}
          isLoadingText="Searching..."
        />
      </SearchContainer>
      <QuestionButton
        iconBefore={['far', 'question-circle']}
        onClick={() => setShowProductCodeModal(true)}
        label={<HelpText>Where do I find the product code?</HelpText>}
        variant="link"
      />
    </Page>
  );
};

export default Home;
