import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import {CONTACT_EMAIL, CONTACT_PHONE} from '../../../constants/contact';

const Instructions = styled.p`
  margin: 0;
`;

const BadConnectionModal = ({onOk, productCode}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
    title="Sorry... Something went wrong!"
  >
    <Instructions>
      We are currently experiencing system problems.
      Our team is working to solve this issue as soon as possible.
      If the problem persists please call {CONTACT_PHONE} or email {CONTACT_EMAIL} to speak with
      customer care about your replacement part needs.
    </Instructions>
  </Modal>
);

export default BadConnectionModal;
