import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage as FormErrorMessage} from '@hookform/error-message';

import {setShipping} from '../../store/orderDetails/orderDetails.actions';
import {selectShipping} from '../../store/orderDetails/orderDetails.selectors';
import ROUTES from '../../constants/routes';
import Page from '../../components/Layout/Page';
import Field from '../../components/Field';
import Select from '../../components/Select';
import ErrorMessage from '../../components/ErrorMessage';
import schema from './validations';
import {changeStep} from '../../store/application/application.actions';

const InputsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  ${(props) => props.theme.breakpoints.lg} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
  }
`;

const FieldContainer = styled.div`
  align-self: stretch;
  margin-bottom: 14px;
  ${(props) => props.theme.breakpoints.lg} {
    width: 49%;
  }
`;

const CheckoutShipping = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const shipping = useSelector(selectShipping);
  const {handleSubmit, control, errors, watch, getValues} = useForm({
    resolver: yupResolver(schema),
    defaultValues: shipping,
  });
  const watchShipToCountry = watch('shipToCountry', '');

  const handleBackClick = () => {
    dispatch(setShipping(getValues()));
    dispatch(changeStep(history, ROUTES.CHECKOUT_QUESTIONS));
  };

  const onSubmit = (data) => {
    dispatch(setShipping(data));
    dispatch(changeStep(history, ROUTES.CHECKOUT_CONTACT));
  };

  return (
    <form>
      <Page
        title="Where should we"
        titleHighlight="ship your part?"
        onBackClick={handleBackClick}
        onNextClick={handleSubmit(onSubmit)}
      >
        <InputsContainer>
          <Controller
            control={control}
            name="shipToFirstName"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Name"
                  placeholder="First Name"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToLastName"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Last Name"
                  placeholder="Last Name"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToAddress1"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToAddress2"
            render={({onChange, onBlur, value}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToCity"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="City"
                  placeholder="City"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToZipCode"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Zip"
                  placeholder="Zip Code"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToCountry"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Country"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                >
                  <Select>
                    <option disabled value="">
                      Select Country
                    </option>
                    {window.WAMBAM.SHIPPING_COUNTRIES_STATES.map(
                      ({value, label}) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ),
                    )}
                  </Select>
                </Field>
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="shipToState"
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="State"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                  disabled={!watchShipToCountry}
                >
                  <Select>
                    <option disabled value="">
                      Select State
                    </option>
                    {window.WAMBAM.SHIPPING_COUNTRIES_STATES.find(
                      (countryItem) => countryItem.value === watchShipToCountry,
                    )?.states.map(({value, label}) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                </Field>
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
        </InputsContainer>
      </Page>
    </form>
  );
};

export default CheckoutShipping;
