import React, {useState} from 'react';
import styled, {ThemeProvider} from 'styled-components';

import PartImage from '../PartImage';
import PartImageModal from './PartImageModal';
import PartInfo from './PartInfo';

const Container = styled.div`
  display: flex;
  height: 45px;
  ${(props) => props.theme.breakpoints.lg} {
    height: 65px;
  }
`;

const InfoActionsContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid
    ${(props) => props.theme.partPrimaryColor || props.theme.colors.secondary};
  border-right: 1px solid
    ${(props) => props.theme.partPrimaryColor || props.theme.colors.secondary};
  padding: 0 14px;
  border-bottom-right-radius: 10px;
  min-width: 0;
  ${(props) => props.theme.breakpoints.lg} {
    > *:not(:last-child) {
      margin-right: 14px;
    }
  }
`;

const selectedTheme = (theme) => ({
  ...theme,
  partPrimaryColor: theme.colors.secondary,
});
const notSelectedTheme = (theme) => ({
  ...theme,
  partPrimaryColor: theme.colors.silver,
});

const PartContainer = ({part, className, children}) => {
  const [partImageModal, setPartImageModal] = useState();

  return (
    <ThemeProvider theme={part.selected ? selectedTheme : notSelectedTheme}>
      <Container>
        {partImageModal && (
          <PartImageModal
            part={partImageModal}
            onOk={() => setPartImageModal(false)}
          />
        )}
        <PartImage
          selected={part.selected}
          part={part}
          onClick={() => setPartImageModal(part)}
        />
        <InfoActionsContainer className={className}>
          <PartInfo part={part} />
          {children}
        </InfoActionsContainer>
      </Container>
    </ThemeProvider>
  );
};

export default PartContainer;
