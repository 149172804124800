import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage as FormErrorMessage} from '@hookform/error-message';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import Field from '../../../components/Field';
import RadioButtons from '../../../components/RadioButtons';
import PhoneInput from '../../../components/PhoneInput';
import ErrorMessage from '../../../components/ErrorMessage';
import schema from './validations';
import {postTooManyPartsContact} from '../../../api/order';

const Header = styled.div`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.xs};
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  flex: 1;
  p {
    margin: 0;
  }
`;

const IconContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: ${(props) => props.theme.fontSizes.lg};
`;

const Body = styled.div`
  align-self: center;
  margin-top: 18px;
  width: 242px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

const FieldContainer = styled.div`
  align-self: stretch;
  margin-bottom: 10px;
`;

const ContactModal = ({onCancel, onSend}) => {
  const {handleSubmit, control, errors, watch} = useForm({
    resolver: yupResolver(schema),
  });
  const watchPreferredContactMethod = watch('preferredContactMethod', '');

  const onSubmit = async (data) => {
    await postTooManyPartsContact(data);
    onSend();
  };

  return (
    <form>
      <Modal
        onClose={onCancel}
        buttons={
          <>
            <Button
              onClick={() => onCancel()}
              iconAfter="times"
              label="Cancel"
            />
            <Button
              onClick={handleSubmit(onSubmit)}
              iconAfter="angle-right"
              label="Send"
            />
          </>
        }
      >
        <Header>
          <IconContainer>
            <FontAwesomeIcon icon="exclamation" />
          </IconContainer>
          <Description>
            <p>
              It looks like you need quite a few parts. We would like to speak
              with you to determine what went wrong to help our quality control.
            </p>
            <p>
              What’s a good number we can call you at during business hours?
            </p>
          </Description>
        </Header>
        <Body>
          <Controller
            control={control}
            name="firstName"
            defaultValue=""
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="First Name"
                  placeholder="First Name"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="lastName"
            defaultValue=""
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Last Name"
                  placeholder="Last Name"
                  required
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="preferredContactMethod"
            defaultValue=""
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <RadioButtons
                  options={[
                    {value: 'phone', label: 'Phone'},
                    {value: 'email', label: 'Email'},
                  ]}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  label="Preferred method of contact"
                  required
                  invalid={invalid}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Phone number"
                  placeholder="Phone number"
                  required={watchPreferredContactMethod === 'phone'}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                  inputAs={PhoneInput}
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
          <Controller
            control={control}
            name="email"
            defaultValue=""
            render={({onChange, onBlur, value, name}, {invalid}) => (
              <FieldContainer>
                <Field
                  label="Email"
                  placeholder="Email"
                  required={watchPreferredContactMethod === 'email'}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  invalid={invalid}
                  type="email"
                />
                <FormErrorMessage
                  as={ErrorMessage}
                  errors={errors}
                  name={name}
                />
              </FieldContainer>
            )}
          />
        </Body>
      </Modal>
    </form>
  );
};

export default ContactModal;
