import React, {useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage as FormErrorMessage} from '@hookform/error-message';

import {setContact} from '../../store/orderDetails/orderDetails.actions';
import {
  selectContact,
  selectQuestions,
  selectShipping,
} from '../../store/orderDetails/orderDetails.selectors';
import {
  selectProduct,
  selectParts,
} from '../../store/product/product.selectors';
import {postOrder} from '../../api/order';
import ROUTES from '../../constants/routes';
import ErrorModal from './components/ErrorModal';
import Page from '../../components/Layout/Page';
import Field from '../../components/Field';
import Button from '../../components/Button';
import PhoneInput from '../../components/PhoneInput';
import ErrorMessage from '../../components/ErrorMessage';
import schema from './validations';
import {changeStep} from '../../store/application/application.actions';

const FieldContainer = styled.div`
  align-self: stretch;
  margin-bottom: 14px;
`;

const TextArea = styled(Field)`
  margin-bottom: 6px;
  textarea {
    height: auto;
    min-height: ${(props) => props.theme.constants.buttonHeight};
    resize: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 16px;
  }
`;

const CheckoutContact = () => {
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const parts = useSelector(selectParts);
  const shipping = useSelector(selectShipping);
  const questions = useSelector(selectQuestions);
  const contact = useSelector(selectContact);
  const {handleSubmit, control, errors, getValues} = useForm({
    resolver: yupResolver(schema),
    defaultValues: contact,
  });
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleBackClick = () => {
    dispatch(setContact(getValues()));
    dispatch(changeStep(history, ROUTES.CHECKOUT_SHIPPING));
  };

  const onSubmit = async (data) => {
    const orderData = {
      product,
      parts,
      contact: data,
      questions,
      shipping,
    };
    setIsLoading(true);
    try {
      await postOrder(orderData);
      dispatch(changeStep(history, ROUTES.CHECKOUT_CONFIRM));
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      dispatch({type: 'RESET_ORDER'});
    }
  };

  return (
    <form>
      <Page
        title="How can we contact you"
        titleHighlight="if we need clarification?"
        onBackClick={handleBackClick}
        buttons={
          <Button
            onClick={handleSubmit(onSubmit)}
            iconAfter="angle-right"
            label="Next"
            isLoading={isLoading}
            isLoadingText="Creating order..."
          />
        }
      >
        {showErrorModal && (
          <ErrorModal
            onOk={() => {
              setShowErrorModal(false);
              dispatch(changeStep(history, ROUTES.HOME));
            }}
          />
        )}
        <Controller
          control={control}
          name="shipToPhone"
          render={({onChange, onBlur, value, name}, {invalid}) => (
            <FieldContainer>
              <Field
                label="Phone number"
                placeholder="Enter phone number"
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                invalid={invalid}
                inputAs={PhoneInput}
              />
              <FormErrorMessage as={ErrorMessage} errors={errors} name={name} />
            </FieldContainer>
          )}
        />
        <Controller
          control={control}
          name="shipToEmail"
          render={({onChange, onBlur, value, name}, {invalid}) => (
            <FieldContainer>
              <Field
                label="E-mail"
                placeholder="Enter mail"
                required
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                invalid={invalid}
                type="email"
              />
              <FormErrorMessage as={ErrorMessage} errors={errors} name={name} />
            </FieldContainer>
          )}
        />
        <Controller
          control={control}
          name="comments"
          render={({onChange, onBlur, value}) => (
            <FieldContainer>
              <TextArea
                label={window.WAMBAM.FORM_LABELS.comments}
                placeholder="Let us know"
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                inputAs="textarea"
                rows="6"
                maxLength="255"
              />
            </FieldContainer>
          )}
        />
      </Page>
    </form>
  );
};

export default CheckoutContact;
