import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  NYCH_GROUP_URL,
} from '../../constants/routes';
import useIsDesktop from '../../hooks/useIsDesktop';
import useClickOutside from '../../hooks/useClickOutside';
import NavLinks from './NavLinks';
import ExternalLink from '../../components/ExternalLink';

const Container = styled.header`
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.powderBlue};
  background-color: ${(props) => props.theme.colors.white};
  z-index: ${(props) => props.theme.zIndex.header};
`;

const Content = styled.div`
  ${(props) => props.theme.containers.lg}
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  flex: 1;
  min-height: ${(props) => props.theme.constants.headerHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  ${(props) => props.theme.breakpoints.lg} {
    justify-content: flex-end;
  }
`;

const HeaderLinks = styled.div`
  display: flex;
  align-items: center;
`;

const SocialNetworkIcon = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.primary};
  padding: 4px 10px;
  :hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const SocialNetworks = styled.div`
  display: flex;
  margin-right: 18px;
  ${SocialNetworkIcon}:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.secondary};
  }
`;

const NychLink = styled(ExternalLink)`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  :hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  ${NychLink}:hover & {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease;
  ${(props) =>
    props.showMenu &&
    css`
      max-height: 600px;
    `}
`;

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef(null);
  const isDesktop = useIsDesktop();
  useClickOutside(containerRef, () => setShowMenu(false));

  const toggleMenu = () => setShowMenu(!showMenu);

  return (
    <Container ref={containerRef}>
      <Content>
        <Wrapper>
          {!isDesktop && (
            <FontAwesomeIcon
              size="2x"
              icon={showMenu ? 'times' : 'bars'}
              onClick={toggleMenu}
            />
          )}
          <HeaderLinks>
            {isDesktop && (
              <SocialNetworks>
                <SocialNetworkIcon href={FACEBOOK_URL}>
                  <FontAwesomeIcon icon={['fab', 'facebook']} />
                </SocialNetworkIcon>
                <SocialNetworkIcon href={INSTAGRAM_URL}>
                  <FontAwesomeIcon icon={['fab', 'instagram']} />
                </SocialNetworkIcon>
              </SocialNetworks>
            )}
            <NychLink href={NYCH_GROUP_URL}>
              www.<Highlight>nych</Highlight>brands.com
            </NychLink>
          </HeaderLinks>
        </Wrapper>
        {!isDesktop && (
          <Menu showMenu={showMenu}>
            <NavLinks onClickLink={() => setShowMenu(false)} />
          </Menu>
        )}
      </Content>
    </Container>
  );
};
export default Header;
