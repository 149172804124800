import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled, {css} from 'styled-components';

const PrimaryButtonStyles = css`
  height: ${(props) => props.theme.constants.buttonHeight};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: ${(props) => props.theme.constants.inputBorderRadius};
  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
        `
      : css`
          :hover {
            background-color: ${(props) => props.theme.colors.primary};
            color: ${(props) => props.theme.colors.white};
          }
        `}
`;

const LinkButtonStyles = css`
  background-color: transparent;
  border: none;
  :hover span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.primary};
  min-width: ${(props) => props.theme.constants.buttonMinWidth};
  font-size: ${(props) => props.theme.fontSizes.sm};
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}
  :focus {
    outline: 0;
  }
  > *:not(:last-child) {
    margin-right: 5px;
  }
  ${(props) =>
    props.variant === 'link' ? LinkButtonStyles : PrimaryButtonStyles}
`;

const Button = ({
  className,
  iconAfter,
  iconBefore,
  isLoading,
  isLoadingText,
  label,
  disabled,
  ...restProps
}) => (
  <StyledButton
    className={className}
    disabled={isLoading || disabled}
    type="button"
    {...restProps}
  >
    {iconBefore && <FontAwesomeIcon icon={iconBefore} />}
    {label && <span>{isLoading ? isLoadingText : label}</span>}
    {iconAfter && <FontAwesomeIcon icon={iconAfter} />}
  </StyledButton>
);

export default Button;
