import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {partChangeQuantity} from '../../store/product/product.actions';
import {
  selectParts,
  selectProduct,
} from '../../store/product/product.selectors';
import ROUTES from '../../constants/routes';
import Page from '../../components/Layout/Page';
import {PartsList, PartContainer} from '../../components/PartsList';
import Button from '../../components/Button';
import {
  changeStep,
  saveSession,
} from '../../store/application/application.actions';

const Count = styled.div`
  border: 1px solid ${(props) => props.theme.colors.silver};
  border-radius: 100%;
  height: ${(props) => props.theme.constants.buttonHeight};
  width: ${(props) => props.theme.constants.buttonHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.primary};
  margin: 0 3px;
`;

const PartsQuantity = () => {
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const parts = useSelector(selectParts);
  const history = useHistory();

  const handleQuantityChange = useCallback(
    (sku, quantity) => {
      dispatch(partChangeQuantity(sku, quantity));
    },
    [dispatch],
  );

  const handleNextClick = () => {
    dispatch(saveSession(ROUTES.CONFIRM_ORDER, product, parts));
    dispatch(changeStep(history, ROUTES.CONFIRM_ORDER));
  };

  return (
    <Page
      title="How many of these parts"
      titleHighlight="do you need?"
      subtitle="Please select the quantity for each part you need"
      onBackClick={() => dispatch(changeStep(history, ROUTES.SELECT_PARTS))}
      onNextClick={handleNextClick}
    >
      <PartsList>
        {parts
          .filter((part) => part.selected)
          .map((part) => (
            <PartContainer key={part.sku} part={part}>
              <Button
                iconBefore="minus"
                onClick={() =>
                  part.quantity > 1 &&
                  handleQuantityChange(part.sku, part.quantity - 1)
                }
              />
              <Count>{part.quantity}</Count>
              <Button
                iconBefore="plus"
                onClick={() =>
                  handleQuantityChange(part.sku, part.quantity + 1)
                }
              />
            </PartContainer>
          ))}
      </PartsList>
    </Page>
  );
};

export default PartsQuantity;
