import styled from 'styled-components';

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.lg};
  color: ${(props) => props.theme.colors.secondary};
  text-align: center;
  margin: 0;
`;

export default Title;
