import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

const Content = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  flex: 1;
  p {
    margin: 0;
  }
`;

const IconContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
  font-size: ${(props) => props.theme.fontSizes.lg};
`;

const ConfirmationModal = ({onOk}) => (
  <Modal buttons={<Button onClick={onOk} iconAfter="check" label="Ok" />}>
    <Content>
      <IconContainer>
        <FontAwesomeIcon icon="exclamation" />
      </IconContainer>
      <Description>
        <p>
          Thank you for providing this information. Someone will contact you
          within 1 business day.
        </p>
      </Description>
    </Content>
  </Modal>
);

export default ConfirmationModal;
