const SEARCH_PRODUCT = 'product/SEARCH_PRODUCT';
const FETCH_PRODUCT = 'product/FETCH_PRODUCT';
const FETCH_PRODUCT_SUCCESS = 'product/FETCH_PRODUCT_SUCCESS';
const FETCH_PRODUCT_ERROR = 'product/FETCH_PRODUCT_ERROR';
const PART_SELECT = 'product/PART_SELECT';
const PART_CHANGE_QUANTITY = 'product/PART_CHANGE_QUANTITY';

const searchProduct = (history, sku) => ({
  type: SEARCH_PRODUCT,
  payload: {
    history,
    sku,
  },
});

const fetchProduct = (sku) => ({
  type: FETCH_PRODUCT,
  payload: {sku},
});

const fetchProductSuccess = (product, parts) => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: {
    product,
    parts,
  },
});

const fetchProductError = (error) => ({
  type: FETCH_PRODUCT_ERROR,
  payload: {error},
});

const partSelect = (sku) => ({
  type: PART_SELECT,
  payload: {sku},
});

const partChangeQuantity = (sku, quantity) => ({
  type: PART_CHANGE_QUANTITY,
  payload: {
    sku,
    quantity,
  },
});

export {
  SEARCH_PRODUCT,
  FETCH_PRODUCT,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_ERROR,
  PART_SELECT,
  PART_CHANGE_QUANTITY,
  searchProduct,
  fetchProduct,
  fetchProductSuccess,
  fetchProductError,
  partSelect,
  partChangeQuantity,
};
