// http://chir.ag/projects/name-that-color/
export default {
  primary: '#1163A7',
  secondary: '#00BEE1',
  bondiBlue: '#0C8CBD',
  toryBlue: '#0F5B98',
  powderBlue: '#B8E0EA',
  white: '#FFFFFF',
  silver: '#CCCCCC',
  nobel: '#B3B3B3',
  poloBlue: '#88B1D3',
  mercury: '#E6E6E6',
  abbey: '#474B4D',
  punch: '#dd3444',
  lima: '#70B810',
  alabaster: '#fcfcfc',
  denim: '#0c7eb8',
  sushi: '#8cc63f',
};
