import styled from 'styled-components';

const Subtitle = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.poloBlue};
  margin: 0;
  text-align: center;
  font-style: italic;
  font-weight: normal;
`;

export default Subtitle;
