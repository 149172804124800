import React from 'react';
import styled from 'styled-components';

import ROUTES from '../../constants/routes';
import useIsDesktop from '../../hooks/useIsDesktop';
import {ReactComponent as LogoNych} from '../../assets/icons/logo-nych_parts.svg';
import NavLinks from './NavLinks';
import Link from '../Link';

const Container = styled.div`
  ${(props) => props.theme.containers.sm}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 25px;
  ${(props) => props.theme.breakpoints.lg} {
    margin-top: 45px;
    margin-bottom: 35px;
  }
`;

const StyledLogoNych = styled(LogoNych)`
  width: 168px;
  height: 78px;
`;

const LinksContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  padding: 0 20px 3px;
  margin-top: 40px;
`;

const Nav = () => {
  const isDesktop = useIsDesktop();

  return (
    <Container>
      <Link to={ROUTES.HOME}>
        <StyledLogoNych />
      </Link>
      {isDesktop && (
        <LinksContainer>
          <NavLinks />
        </LinksContainer>
      )}
    </Container>
  );
};

export default Nav;
