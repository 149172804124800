import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import ROUTES, {NYCH_GROUP_URL} from '../../constants/routes';
import Title from '../../components/Title';
import ExternalLink from '../../components/ExternalLink';
import BrandsList from './BrandsList';

const Container = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.secondary};
  background-color: ${(props) => props.theme.colors.alabaster};
  box-shadow: inset 0px -150px 70px -70px rgba(0, 0, 0, 0.3);
`;

const Content = styled.div`
  ${(props) => props.theme.containers.lg}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled(Title)`
  margin-top: 24px;
`;

const Highlight = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const Description = styled.p`
  color: ${(props) => props.theme.colors.poloBlue};
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-style: italic;
  margin: 0;
  max-width: 580px;
  text-align: center;
`;

const StyledExternalLink = styled(ExternalLink)`
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.primary};
  font-style: italic;
  text-align: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.secondary};
  margin-right: 5px;
`;

const NychLink = styled.span`
  font-weight: bold;
  font-style: normal;
`;

const NychLinkHighlight = styled.span`
  color: ${(props) => props.theme.colors.secondary};
`;

const WhoWeAre = () => (
  <Container id={ROUTES.WHO_WE_ARE.replace('#', '')}>
    <Content>
      <StyledTitle>
        Who <Highlight>we are</Highlight>
      </StyledTitle>
      <Description>
        Nych Brands is a collection of brands that offer a variety of outdoor
        products such as fencing, privacy screens and pet containment products.
        Designed with the do-it-yourself customer in mind, Nych Brands strives
        to design products that are attractive and DIY friendly.
      </Description>
      <StyledExternalLink href={NYCH_GROUP_URL}>
        <StyledFontAwesomeIcon icon="angle-right" />
        Learn more about us at{' '}
        <NychLink>
          www.<NychLinkHighlight>nych</NychLinkHighlight>brands.com
        </NychLink>
      </StyledExternalLink>
      <BrandsList />
    </Content>
  </Container>
);

export default WhoWeAre;
