import React, {useMemo} from 'react';

import Field from '../Field';
import Select from '../Select';

const FormChoices = ({
  className,
  field,
  label,
  onChange,
  value,
  required,
  ...restProps
}) => {
  const choices = useMemo(
    () =>
      window.WAMBAM.FORM_CHOICES.filter(
        ({field: choiceField}) => choiceField === field,
      ),
    [field],
  );

  return (
    <Field
      className={className}
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      required={required}
      {...restProps}
    >
      <Select>
        <option disabled value="">
          Please select
        </option>
        {choices.map(({value, label}) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Select>
    </Field>
  );
};

export default FormChoices;
