import React from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {BaseInputStyles, PlaceholderStyles} from '../Input';

const Container = styled.div`
  position: relative;
  height: ${(props) => props.theme.constants.buttonHeight};
`;

const StyledSelect = styled.select`
  ${BaseInputStyles};
  width: 100%;
  height: 100%;
  appearance: none;
  ${(props) =>
    !props.value &&
    css`
      ${PlaceholderStyles};
      option:not(:first-child) {
        color: initial;
      }
    `}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 32px;
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-top-right-radius: ${(props) =>
    props.theme.constants.inputBorderRadius};
  border-bottom-right-radius: ${(props) =>
    props.theme.constants.inputBorderRadius};
  background-color: ${(props) => props.theme.colors.mercury};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.abbey};
`;

const Select = ({className, children, ...restProps}) => (
  <Container className={className}>
    <StyledSelect {...restProps}>{children}</StyledSelect>
    <IconContainer>
      <StyledFontAwesomeIcon icon="caret-down" />
    </IconContainer>
  </Container>
);

export default Select;
