import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import ROUTES from '../../constants/routes';
import Link from '../Link';
import {toggleShowFooter} from '../../store/application/application.actions';
import {selectShowFooter} from '../../store/application/application.selectors';

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  padding: 15px 20px;
  ${(props) => props.theme.breakpoints.lg} {
    padding: 0;
    margin: 0 20px;
    font-style: italic;
    font-size: ${(props) => props.theme.fontSizes.sm};
    :hover {
      border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
    }
  }
`;

const NavLinks = ({onClickLink}) => {
  const showFooter = useSelector(selectShowFooter);
  const dispatch = useDispatch();

  const handleHomeLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    onClickLink && onClickLink();
  };

  const handleContactClick = () => {
    if (!showFooter) dispatch(toggleShowFooter());
    onClickLink && onClickLink();
  };

  return (
    <>
      <StyledLink onClick={handleHomeLinkClick} to={ROUTES.HOME}>
        Home
      </StyledLink>
      <StyledLink onClick={onClickLink} as="a" href={ROUTES.WHO_WE_ARE}>
        Who we are
      </StyledLink>
      <StyledLink onClick={handleContactClick} as="a" href={ROUTES.CONTACT}>
        Contact
      </StyledLink>
    </>
  );
};

export default NavLinks;
