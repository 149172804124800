import breakpoints from './breakpoints';
import colors from './colors';
import constants from './constants';
import containers from './containers';
import fontSizes from './fontSizes';
import zIndex from './zIndex';

export default {
  breakpoints,
  colors,
  constants,
  containers,
  fontSizes,
  zIndex,
};
