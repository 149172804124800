import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.colors.punch};
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: bold;
  margin-top: 10px;
`;

export default ErrorMessage;
