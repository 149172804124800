import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {
  selectProduct,
  selectParts,
} from '../../store/product/product.selectors';
import {partSelect} from '../../store/product/product.actions';
import ROUTES from '../../constants/routes';
import Page from '../../components/Layout/Page';
import {PartsList, PartContainer} from '../../components/PartsList';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import {
  changeStep,
  saveSession,
} from '../../store/application/application.actions';
import ContactModal from './components/ContactModal';
import ConfirmationModal from './components/ConfirmationModal';

const MAX_PARTS_QUANTITY = 9999;

const SelectButton = styled(Button)`
  min-width: 88px;
  ${(props) =>
    props.selected &&
    css`
      background-color: ${(props) => props.theme.colors.secondary};
      border-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.white};
      font-weight: bold;

      :hover {
        background-color: ${(props) => props.theme.colors.secondary};
        border-color: ${(props) => props.theme.colors.secondary};
        filter: brightness(0.85);
      }
    `}
  ${(props) => props.theme.breakpoints.lg} {
    width: 100px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  align-self: stretch;
`;

const SelectParts = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const parts = useSelector(selectParts);
  const [error, setError] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handlePartSelect = (sku) => {
    setError(false);
    dispatch(partSelect(sku));
  };

  const handleNextClick = () => {
    const selectedPartsQuantity = parts.filter((part) => part.selected).length;
    if (selectedPartsQuantity > 0) {
      if (selectedPartsQuantity > MAX_PARTS_QUANTITY) {
        setShowContactModal(true);
      } else {
        dispatch(saveSession(ROUTES.PARTS_QUANTITY, product, parts));
        dispatch(changeStep(history, ROUTES.PARTS_QUANTITY));
      }
    } else {
      setError(true);
    }
  };

  const handleSendContact = () => {
    setShowContactModal(false);
    dispatch({type: 'RESET_ORDER'});
    setShowConfirmationModal(true);
  };

  return (
    <Page
      title="Click on the"
      titleHighlight="part you need"
      subtitle="Please select all the parts you need"
      onBackClick={() => dispatch(changeStep(history, ROUTES.CONFIRM_PRODUCT))}
      onNextClick={handleNextClick}
    >
      {showContactModal && (
        <ContactModal
          onCancel={() => setShowContactModal(false)}
          onSend={handleSendContact}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          onOk={() => dispatch(changeStep(history, ROUTES.HOME))}
        />
      )}
      <PartsList>
        {parts.map((part) => (
          <PartContainer key={part.sku} part={part}>
            <SelectButton
              iconBefore="check"
              label={part.selected ? 'Selected' : 'Select'}
              onClick={() => handlePartSelect(part.sku)}
              selected={part.selected}
            />
          </PartContainer>
        ))}
        {error && (
          <StyledErrorMessage>
            Please select at least one part.
          </StyledErrorMessage>
        )}
      </PartsList>
    </Page>
  );
};

export default SelectParts;
