import styled, {css} from 'styled-components';

export const PlaceholderStyles = css`
  color: ${(props) => props.theme.colors.nobel};
  font-style: italic;
`;

export const BaseInputStyles = css`
  height: ${(props) => props.theme.constants.buttonHeight};
  padding: 0 15px;
  border: 1px solid ${(props) => props.theme.colors.silver};
  border-radius: ${(props) => props.theme.constants.inputBorderRadius};
  font-size: ${(props) => props.theme.fontSizes.sm};
  ::placeholder {
    ${PlaceholderStyles};
  }
  :focus {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }
  ${(props) =>
    props.invalid &&
    css`
      border-color: ${(props) => props.theme.colors.punch};
    `}
`;

const Input = styled.input`
  ${BaseInputStyles};
`;

export default Input;
