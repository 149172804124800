import React from 'react';
import styled, {css} from 'styled-components';
import noImage from "../../assets/images/no-image.png";

const Container = styled.div`
  cursor: pointer;
  border: 1px solid
    ${(props) => props.theme.partPrimaryColor || props.theme.colors.secondary};
  height: 100%;
  min-width: 49px;
  border-radius: 10px;
  margin-right: 4px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.lg} {
    width: 72px;
  }
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 0px 15px 0px rgba(30, 164, 224, 0.45);
    `}
  ::before {
    content: '';
    border-bottom: 1px solid
      ${(props) => props.theme.partPrimaryColor || props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.white};
    transform: rotate(-45deg);
    height: 20px;
    width: 50px;
    position: absolute;
    top: -1px;
    left: -15px;
  }
`;

const CodeLetter = styled.span`
  color: ${(props) =>
    props.theme.partPrimaryColor || props.theme.colors.secondary};
  font-weight: bold;
  position: absolute;
  margin: 2px 0 0 5px;
  top: 0;
  left: 0;
`;

const Image = styled.img`
  max-width: 80%;
  height: auto;
  max-height: 80%;
`;

const CodeNumber = styled.span`
  color: ${(props) =>
    props.theme.partPrimaryColor || props.theme.colors.secondary};
  font-weight: bold;
  position: absolute;
  margin: 2px 5px 0 0;
  top: 0;
  right: 0;
`;

const PartImage = ({part, onClick, selected}) => {
  const letter = part.letter[0];
  const number = part.letter[2];

  return (
    <Container onClick={onClick} selected={selected}>
      <CodeLetter>{letter}</CodeLetter>
      <Image alt={part.name} src={part.image?.url_small || part.image?.urlSmall || noImage} />
      {number && <CodeNumber>#{number}</CodeNumber>}
    </Container>
  );
};

export default PartImage;
