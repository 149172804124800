import React, {useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {ENCLO_URL, WAMBAM_URL, ZIPPITY_URL} from '../../constants/routes';
import ExternalLink from '../ExternalLink';
import {ReactComponent as LogoEnclo} from '../../assets/icons/logo-enclo.svg';
import {ReactComponent as LogoWambam} from '../../assets/icons/logo-wambam.svg';
import {ReactComponent as LogoZippity} from '../../assets/icons/logo-zippity.svg';
import useIsDesktop from '../../hooks/useIsDesktop';

const Brands = styled.div`
  display: flex;
  margin-bottom: 80px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.theme.breakpoints.lg} {
    justify-content: center;
  }
`;

const BrandContainer = styled(ExternalLink)`
  width: 235px;
`;

const BrandLogo = styled.svg`
  fill: ${(props) => props.theme.colors.primary};
  :hover {
    fill: ${(props) => props.theme.colors.secondary};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const brands = [
  {url: ENCLO_URL, logo: LogoEnclo},
  {url: WAMBAM_URL, logo: LogoWambam},
  {url: ZIPPITY_URL, logo: LogoZippity},
];

const BrandsList = () => {
  const isDesktop = useIsDesktop();
  const [showBrandIndex, setShowBrandIndex] = useState(0);

  const handlePreviousBrand = () => {
    if (showBrandIndex > 0) setShowBrandIndex(showBrandIndex - 1);
  };

  const handleNextBrand = () => {
    if (showBrandIndex < brands.length - 1)
      setShowBrandIndex(showBrandIndex + 1);
  };

  return (
    <Brands>
      {!isDesktop && (
        <StyledIcon icon="angle-left" size="2x" onClick={handlePreviousBrand} />
      )}
      {brands.map((brand, index) =>
        isDesktop || showBrandIndex === index ? (
          <BrandContainer key={index} href={brand.url}>
            <BrandLogo as={brand.logo} />
          </BrandContainer>
        ) : null,
      )}
      {!isDesktop && (
        <StyledIcon icon="angle-right" size="2x" onClick={handleNextBrand} />
      )}
    </Brands>
  );
};

export default BrandsList;
