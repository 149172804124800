import styled from 'styled-components';

const PartsList = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default PartsList;
