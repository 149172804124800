import * as yup from 'yup';

const schema = yup.object().shape({
  productPurchasedAt: yup.string().required(),
  whatWentWrong: yup.string().required(),
  whatWentWrongInformation: yup.string(),
  hasProductBox: yup.boolean().required(),
  batchNumber: yup.string().when('hasProductBox', {
    is: true,
    then: yup.string().required(),
  }),
});

export default schema;
