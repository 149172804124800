const API_URL = '/api/v1';

const showError = (error) =>
  process.env.NODE_ENV !== 'production' && console.error(error);

const request = async (method, path, data) => {
  const options = {
    method,
    headers: {'Content-Type': 'application/json'},
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  try {
    const res = await fetch(`${API_URL}${path}`, options);
    if (res.status >= 200 && res.status < 300) {
      return res.status === 204 ? null : res.json();
    } else {
      const error = await res.json();
      throw error;
    }
  } catch (error) {
    showError(error);
    throw error;
  }
};

const postRequest = (path, data) => request('POST', path, data);

const putRequest = (path, data) => request('PUT', path, data);

const patchRequest = (path, data) => request('PATCH', path, data);

const deleteRequest = (path, data) => request('DELETE', path, data);

const getQs = (params) =>
  Object.keys(params || {}).reduce((accum, key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      value.forEach((v) => accum.append(key, v));
    } else {
      accum.append(key, value);
    }
    return accum;
  }, new URLSearchParams());

const getRequest = (path, params) => {
  const fullPath = params ? `${path}?${getQs(params)}` : path;
  return request('GET', fullPath);
};

export {postRequest, putRequest, patchRequest, deleteRequest, getRequest};
