import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Page from '../../components/Layout/Page';

const MessageContainer = styled.div`
  position: relative;
  margin-top: 65px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: 15px;
  max-width: 422px;
  width: 100%;
  padding: 50px 20px 15px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.primary};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  width: 76px;
  height: 76px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.secondary};
  border: 8px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes.xl};
`;

const CheckoutConfirm = () => (
  <Page title="Thank you for" titleHighlight="contacting us!">
    <MessageContainer>
      <IconContainer>
        <FontAwesomeIcon icon="check" />
      </IconContainer>
      Your part request has been submitted for review.
      Our customer care team may contact you if more information is needed to process the order.
      If your request is approved,
      your parts <strong>will be shipped within </strong>1 business day and will
      ship either FedEx or USPS Priority from Charlotte, NC.
      Your parts will arrive within 2-5 business days based on location.
      East coast customers typically receive parts in 2 business days while it can take up to 5
      business days for those who live on the West coast. Your tracking number will be emailed to
      you once your parts ship.
    </MessageContainer>
  </Page>
);

export default CheckoutConfirm;
