import React from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

const Instructions = styled.p`
  margin: 0;
`;

const ErrorModal = ({onOk}) => (
  <Modal
    onClose={onOk}
    buttons={<Button onClick={() => onOk()} iconBefore="check" label="Ok" />}
    title="An error ocurred:"
  >
    <Instructions>An error ocurred, please come back later.</Instructions>
  </Modal>
);

export default ErrorModal;
