import React from 'react';

import Input from '../Input';

const NUMBERS_REGEX = /^[0-9]+$/;

const PhoneInput = ({onChange, ...restProps}) => {
  const handleChange = (event) => {
    if (event.target.value === '' || NUMBERS_REGEX.test(event.target.value)) {
      onChange(event);
    }
  };

  return (
    <Input {...restProps} type="tel" maxLength={11} onChange={handleChange} />
  );
};

export default PhoneInput;
