import {postRequest, deleteRequest, getRequest} from './base';

const getSession = async () => {
  return await getRequest(`/session/`);
};

const postSession = async (session) => {
  return await postRequest(`/session/`, {
    cart: session,
  });
};

const deleteSession = () => deleteRequest(`/session/`);

export {getSession, postSession, deleteSession};
