import React from 'react';
import styled from 'styled-components';

const PartInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const PartName = styled.span`
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.md};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SerialNumber = styled.span`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.xs};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PartSerialNumber = styled.span`
  color: ${(props) => props.theme.colors.nobel};
  font-weight: normal;
`;

const Part = ({part}) => (
  <PartInfo>
    <PartName>{part.name}</PartName>
    <SerialNumber>
      S/N: <PartSerialNumber>{part.sku}</PartSerialNumber>
    </SerialNumber>
  </PartInfo>
);

export default Part;
