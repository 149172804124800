import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {
  selectParts,
  selectProduct,
} from '../../store/product/product.selectors';
import ROUTES from '../../constants/routes';
import Page from '../../components/Layout/Page';
import {PartsList, PartContainer} from '../../components/PartsList';
import Button from '../../components/Button';
import {
  changeStep,
  saveSession,
} from '../../store/application/application.actions';

const ConfirmButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.sushi};
  border-color: ${(props) => props.theme.colors.sushi};
  color: ${(props) => props.theme.colors.white};
  :hover {
    background-color: ${(props) => props.theme.colors.lima};
    border-color: ${(props) => props.theme.colors.lima};
  }
`;

const Count = styled.div`
  border-radius: 100%;
  height: ${(props) => props.theme.constants.buttonHeight};
  width: ${(props) => props.theme.constants.buttonHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes.md};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.secondary};
`;

const ConfirmOrder = () => {
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const parts = useSelector(selectParts);
  const history = useHistory();

  const handleNextClick = () => {
    dispatch(saveSession(ROUTES.CHECKOUT_QUESTIONS, product, parts));
    dispatch(changeStep(history, ROUTES.CHECKOUT_QUESTIONS));
  };

  return (
    <Page
      title="Confirm"
      titleHighlight="your order"
      subtitle="Please check you have all you need!"
      onBackClick={() => dispatch(changeStep(history, ROUTES.PARTS_QUANTITY))}
      buttons={
        <ConfirmButton
          onClick={handleNextClick}
          iconBefore="check"
          label="Confirm"
        />
      }
    >
      <PartsList>
        {parts
          .filter((part) => part.selected)
          .map((part) => (
            <PartContainer key={part.sku} part={part}>
              <Count>{part.quantity}</Count>
            </PartContainer>
          ))}
      </PartsList>
    </Page>
  );
};

export default ConfirmOrder;
