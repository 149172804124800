import {combineReducers, createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';

import applicationReducer from './application/application.reducer';
import orderDetailsReducer from './orderDetails/orderDetails.reducer';
import productReducer from './product/product.reducer';
import {deleteSession} from '../api/session';

import productSagas from './product/product.sagas';
import applicationSagas from './application/application.sagas';

const appReducer = combineReducers({
  application: applicationReducer,
  orderDetails: orderDetailsReducer,
  product: productReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ORDER') {
    state = undefined;
    deleteSession();
  }

  return appReducer(state, action);
};

function* rootSaga() {
  yield all([productSagas(), applicationSagas()]);
}

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));

sagaMiddleWare.run(rootSaga);

export default store;
