import * as yup from 'yup';

const schema = yup.object().shape({
  shipToFirstName: yup.string().required(),
  shipToLastName: yup.string().required(),
  shipToAddress1: yup.string().required(),
  shipToAddress2: yup.string(),
  shipToCountry: yup.string().required(),
  shipToCity: yup.string().required(),
  shipToState: yup.string().required(),
  shipToZipCode: yup.string().required(),
});

export default schema;
