import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useDispatch, useSelector} from 'react-redux';
import noImage from "../../assets/images/no-image.png";


import {
  selectProduct,
  selectParts,
} from '../../store/product/product.selectors';
import ROUTES from '../../constants/routes';
import Page from '../../components/Layout/Page';
import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import {
  changeStep,
  saveSession,
} from '../../store/application/application.actions';

const StyledPage = styled(Page)`
  width: 100%;
  max-width: ${(props) => props.theme.constants.actionButtonsContainer};
  align-self: center;
`;

const ProductImageContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.silver};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const StyledExternalLink = styled(ExternalLink)`
  align-self: flex-start;
  margin-bottom: 10px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.primary};
  margin-right: 5px;
`;

const ConfirmProduct = () => {
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const parts = useSelector(selectParts);
  const history = useHistory();

  const handleBackClick = () => {
    dispatch({type: 'RESET_ORDER'});
    dispatch(changeStep(history, ROUTES.HOME));
  };

  const handleYesClick = () => {
    dispatch(saveSession(ROUTES.SELECT_PARTS, product, parts));
    dispatch(changeStep(history, ROUTES.SELECT_PARTS));
  };

  return (
    <StyledPage
      title="Is this"
      titleHighlight="your product?"
      onBackClick={handleBackClick}
      buttons={
        <>
          <Button onClick={handleBackClick} iconBefore="times" label="No" />
          <Button onClick={handleYesClick} iconBefore="check" label="Yes" />
        </>
      }
    >
      <ProductImageContainer>
        <ProductImage src={product.images?.length > 0 ? product.images[0] : noImage } alt={product.name} />

      </ProductImageContainer>
      {product.websiteUrl && (
        <StyledExternalLink href={product.websiteUrl}>
          <StyledFontAwesomeIcon icon="angle-right" />
          Go to product page
        </StyledExternalLink>
      )}
    </StyledPage>
  );
};

export default ConfirmProduct;
