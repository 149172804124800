import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import styled, {keyframes} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import useIsDesktop from '../../hooks/useIsDesktop';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  z-index: ${(props) => props.theme.zIndex.modal};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.55);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  flex: 1;
  max-width: 100%;
  align-self: stretch;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
  ${(props) => props.theme.breakpoints.lg} {
    flex: initial;
    align-self: initial;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.silver};
    box-shadow: 0px 0px 70px 0px ${(props) => props.theme.colors.silver};
    padding: 28px 26px 32px;
    width: 520px;
    max-width: 90%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.primary};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  > button {
    flex: 1;
    max-width: 250px;
  }
  > button:not(:last-child) {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.lg} {
    margin-top: 15px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.secondary};
  font-size: ${(props) => props.theme.fontSizes.lg};
`;

const Body = styled.div`
  flex: 1;
  align-self: center;
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.fontSizes.sm};
  > :not(:last-child) {
    margin-bottom: 14px;
  }
`;

const Modal = ({buttons, children, className, onClose, title}) => {
  const isDesktop = useIsDesktop();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = null);
  }, []);

  return createPortal(
    <Container>
      <Content className={className}>
        {!isDesktop && onClose && (
          <Header>
            <CloseIcon size="lg" icon={'times'} onClick={onClose} />
          </Header>
        )}
        <Title>{title}</Title>
        <Body>{children}</Body>
        {buttons && <ButtonsContainer>{buttons}</ButtonsContainer>}
      </Content>
    </Container>,
    document.getElementById('modal'),
  );
};
export default Modal;
