import React, {useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage as FormErrorMessage} from '@hookform/error-message';

import {setQuestions} from '../../store/orderDetails/orderDetails.actions';
import {selectQuestions} from '../../store/orderDetails/orderDetails.selectors';
import ROUTES from '../../constants/routes';
import BatchNumberModal from './components/BatchNumberModal';
import Page from '../../components/Layout/Page';
import Field from '../../components/Field';
import Button from '../../components/Button';
import FormChoices from '../../components/FormChoices';
import RadioButtons from '../../components/RadioButtons';
import ErrorMessage from '../../components/ErrorMessage';
import {whatWentWrongNeedsInformation} from '../../utils/questions';
import schema from './validations';
import {changeStep} from '../../store/application/application.actions';

const FieldContainer = styled.div`
  align-self: stretch;
  margin-bottom: 14px;
`;

const QuestionButton = styled(Button)`
  margin: 6px 0 15px 15px;
  align-self: flex-start;
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

const HelpText = styled.span`
  color: ${(props) => props.theme.colors.nobel};
`;

const CheckoutQuestions = () => {
  const dispatch = useDispatch();
  const questions = useSelector(selectQuestions);
  const [showBatchNumberModal, setShowBatchNumberModal] = useState(false);
  const history = useHistory();
  const {handleSubmit, control, errors, watch, getValues} = useForm({
    resolver: yupResolver(schema),
    defaultValues: questions,
  });
  const watchWhatWentWrong = watch('whatWentWrong', '');
  const watchHasProductBox = watch('hasProductBox', false);

  const handleBackClick = () => {
    dispatch(setQuestions(getValues()));
    dispatch(changeStep(history, ROUTES.CONFIRM_ORDER));
  };

  const onSubmit = (data) => {
    dispatch(setQuestions(data));
    dispatch(changeStep(history, ROUTES.CHECKOUT_SHIPPING));
  };

  return (
    <form>
      <Page
        title="Help us find"
        titleHighlight="what went wrong"
        onBackClick={handleBackClick}
        onNextClick={handleSubmit(onSubmit)}
      >
        {showBatchNumberModal && (
          <BatchNumberModal onOk={() => setShowBatchNumberModal(false)} />
        )}
        <Controller
          control={control}
          name="productPurchasedAt"
          render={({onChange, onBlur, value, name}, {invalid}) => (
            <FieldContainer>
              <FormChoices
                field="purchased_at"
                label={window.WAMBAM.FORM_LABELS.purchasedAt}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                required
                invalid={invalid}
              />
              <FormErrorMessage as={ErrorMessage} errors={errors} name={name} />
            </FieldContainer>
          )}
        />
        <Controller
          control={control}
          name="whatWentWrong"
          render={({onChange, onBlur, value, name}, {invalid}) => (
            <FieldContainer>
              <FormChoices
                field="what_went_wrong"
                label={window.WAMBAM.FORM_LABELS.whatWentWrong}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                required
                invalid={invalid}
              />
              <FormErrorMessage as={ErrorMessage} errors={errors} name={name} />
            </FieldContainer>
          )}
        />
        {whatWentWrongNeedsInformation(watchWhatWentWrong) && (
          <Controller
            control={control}
            name="whatWentWrongInformation"
            defaultValue={questions.whatWentWrongInformation || ''}
            render={({onChange, onBlur, value}) => (
              <FieldContainer>
                <Field
                  label={window.WAMBAM.FORM_LABELS.whatWentWrongInformation}
                  placeholder="More information"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              </FieldContainer>
            )}
          />
        )}
        <Controller
          control={control}
          name="hasProductBox"
          render={({onChange, onBlur, value, name}, {invalid}) => (
            <FieldContainer>
              <RadioButtons
                options={[
                  {value: true, label: 'Yes'},
                  {value: false, label: 'No'},
                ]}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                label={window.WAMBAM.FORM_LABELS.hasProductBox}
                required
                invalid={invalid}
              />
              <FormErrorMessage as={ErrorMessage} errors={errors} name={name} />
            </FieldContainer>
          )}
        />
        {watchHasProductBox && (
          <>
            <Controller
              control={control}
              name="batchNumber"
              defaultValue={questions.batchNumber || ''}
              render={({onChange, onBlur, value, name}, {invalid}) => (
                <FieldContainer>
                  <Field
                    label={window.WAMBAM.FORM_LABELS.batchNumber}
                    placeholder="Batch number"
                    required={watchHasProductBox}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    invalid={invalid}
                  />
                  <FormErrorMessage
                    as={ErrorMessage}
                    errors={errors}
                    name={name}
                  />
                  <QuestionButton
                    iconBefore={['far', 'question-circle']}
                    onClick={() => setShowBatchNumberModal(true)}
                    label={
                      <HelpText>Where do I find the batch number?</HelpText>
                    }
                    variant="link"
                  />
                </FieldContainer>
              )}
            />
          </>
        )}
      </Page>
    </form>
  );
};

export default CheckoutQuestions;
