import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faPhone,
  faSearch,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faCheck,
  faSearchPlus,
  faInfo,
  faPlus,
  faMinus,
  faExclamation,
  faCaretUp,
  faCaretDown,
  faMapMarkerAlt,
  faBars,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  faEnvelope,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faPhone,
  faSearch,
  faEnvelope,
  faQuestionCircle,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faCheck,
  faSearchPlus,
  faInfo,
  faPlus,
  faMinus,
  faExclamation,
  faCaretUp,
  faCaretDown,
  faFacebookF,
  faInstagram,
  faMapMarkerAlt,
  faBars,
  faFacebook,
  faAngleUp,
  faAngleDown,
);
