import React, {useEffect} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import ROUTES from '../constants/routes';
import CheckoutConfirm from './CheckoutConfirm';
import CheckoutContact from './CheckoutContact';
import CheckoutQuestions from './CheckoutQuestions';
import CheckoutShipping from './CheckoutShipping';
import ConfirmProduct from './ConfirmProduct';
import Home from './Home';
import PartsQuantity from './PartsQuantity';
import ConfirmOrder from './ConfirmOrder';
import SelectParts from './SelectParts';
import Spinner from '../components/Spinner';
import {selectIsAppBootstrapReady} from '../store/application/application.selectors';
import {initApplicationBootstrap} from '../store/application/application.actions';

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
`;

const Routes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAppBootstrapReady = useSelector(selectIsAppBootstrapReady);

  useEffect(() => {
    dispatch(initApplicationBootstrap(history));
  }, [history, dispatch]);

  if (!isAppBootstrapReady) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  return (
    <Switch>
      <Route path={ROUTES.CHECKOUT_CONFIRM}>
        <CheckoutConfirm />
      </Route>
      <Route path={ROUTES.CHECKOUT_CONTACT}>
        <CheckoutContact />
      </Route>
      <Route path={ROUTES.CHECKOUT_QUESTIONS}>
        <CheckoutQuestions />
      </Route>
      <Route path={ROUTES.CHECKOUT_SHIPPING}>
        <CheckoutShipping />
      </Route>
      <Route path={ROUTES.CONFIRM_PRODUCT}>
        <ConfirmProduct />
      </Route>
      <Route path={ROUTES.CONFIRM_ORDER}>
        <ConfirmOrder />
      </Route>
      <Route path={ROUTES.HOME} exact>
        <Home />
      </Route>
      <Route path={ROUTES.PARTS_QUANTITY}>
        <PartsQuantity />
      </Route>
      <Route path={ROUTES.SELECT_PARTS}>
        <SelectParts />
      </Route>
    </Switch>
  );
};

export default Routes;
