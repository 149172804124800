import {
  APPLICATION_BOOTSTRAP_SUCCESS,
  APPLICATION_TOGGLE_SHOW_FOOTER,
} from './application.actions';

const initialState = {
  isBoostrapReady: false,
  showFooter: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_BOOTSTRAP_SUCCESS: {
      return {
        ...state,
        isBoostrapReady: true,
      };
    }
    case APPLICATION_TOGGLE_SHOW_FOOTER: {
      return {
        ...state,
        showFooter: !state.showFooter,
      };
    }
    case 'RESET_ORDER': {
      return {
        ...state,
        isBoostrapReady: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
